import BaseLogo from '../assets/logo/Base.png';
import ETHLogo from '../assets/logo/ETH.png';
import BNBLogo from '../assets/logo/BNB.png'

export interface Chain {
    name: string;
    chainId: bigint;
    symbol: string;
    decimals: number;
    rpcUrl: string[];
    blockExplorer: string;
    uniswapV3: {
        positionManager: string;
        factory: string;
        weth: string;
        quoteContract: string;
        swapRouter: string;
        permit2: string;
        universalRouter: string;
        usdt: string;
        usdtFeePool: number;
    };
    fairLaunchFactory: string;
    feePool: number;
    icon: any;
    blockSeconds: number;
}

export interface Chains {
    [key: number]: Chain;
}


const _CHAINS :{
    [key: number]: Chain;
} = {
    1: {
        "name": "Ethereum Mainnet",
        "chainId": BigInt(1), 
        "symbol": "ETH",
        "decimals": 18,
        "rpcUrl":[ "https://ethereum-rpc.publicnode.com"],
        "blockExplorer": "https://etherscan.io",

        "uniswapV3": {
            "positionManager": "0xC36442b4a4522E871399CD717aBDD847Ab11FE88",
            "factory": "0xC36442b4a4522E871399CD717aBDD847Ab11FE88",
            "weth": "0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2",
            "quoteContract": "0x61fFE014bA17989E743c5F6cB21bF9697530B21e",
            "swapRouter": "0x68b3465833fb72A70ecDF485E0e4C7bD8665Fc45",
            "permit2": "0x000000000022D473030F116dDEE9F6B43aC78BA3",
            "universalRouter": "0x3fC91A3afd70395Cd496C647d5a6CC9D4B2b7FAD",
            "usdt": "0xdAC17F958D2ee523a2206206994597C13D831ec7",
            "usdtFeePool": 3000
        },

        "fairLaunchFactory": "0xE079e16166A8dFAC8B2F8477AD2c1F4062170C3F", 
        "feePool": 3000,
        "icon": ETHLogo,
        "blockSeconds": 13
    }, 

    56: {
        "name": "BSC",
        "chainId": BigInt(56),
        "symbol": "BNB",
        "decimals": 18,
        "rpcUrl": ["https://bsc-dataseed.binance.org"],
        "blockExplorer": "https://bscscan.com",

        "uniswapV3": {
            "positionManager": "0x46A15B0b27311cedF172AB29E4f4766fbE7F4364",
            "factory": "0x0BFbCF9fa4f9C56B0F40a671Ad40E0805A091865",
            "weth": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c",
            "quoteContract": "0xB048Bbc1Ee6b733FFfCFb9e9CeF7375518e25997",
            "swapRouter": "0x1b81D678ffb9C0263b24A97847620C99d213eB14",
            "permit2": "0x31c2F6fcFf4F8759b3Bd5Bf0e1084A055615c768",
            "universalRouter": "0x1A0A18AC4BECDDbd6389559687d1A73d8927E416",
            "usdt": "0x55d398326f99059fF775485246999027B3197955",
            "usdtFeePool": 500,
        },

        "fairLaunchFactory": "0xE079e16166A8dFAC8B2F8477AD2c1F4062170C3F",
        "feePool": 2500,
        "icon": BNBLogo,
        "blockSeconds": 3
    },

    8453: {
        "name": "Base",
        "chainId": BigInt(8453),
        "decimals": 18,
        "symbol": "ETH",
        "rpcUrl": ["https://mainnet.base.org"],
        "blockExplorer": "https://basescan.org",

        "uniswapV3": {
            "positionManager": "0x03a520b32C04BF3bEEf7BEb72E919cf822Ed34f1",
            "factory": "0x33128a8fC17869897dcE68Ed026d694621f6FDfD",
            "weth": "0x4200000000000000000000000000000000000006",
            "quoteContract": "0x3d4e44Eb1374240CE5F1B871ab261CD16335B76a",
            "swapRouter": "0x2626664c2603336E57B271c5C0b26F421741e481",
            "permit2": "0x000000000022D473030F116dDEE9F6B43aC78BA3",
            "universalRouter": "0x3fC91A3afd70395Cd496C647d5a6CC9D4B2b7FAD",
            "usdt": "0xdAC17F958D2ee523a2206206994597C13D831ec7",
            "usdtFeePool": 3000
        },
        "fairLaunchFactory": "0x3d904547C301e6a89f1c63f3Ba35dCbFd28222f7",
        "feePool": 3000,
        "icon": BaseLogo,
        "blockSeconds": 2
    },

    97: {
        "name": "BSC Testnet",
        "chainId": BigInt(97),
        "symbol": "testBNB",
        "decimals": 18,
        "rpcUrl": ["https://data-seed-prebsc-1-s1.binance.org:8545"],
        "blockExplorer": "https://testnet.bscscan.com",

        "uniswapV3": {
            "positionManager": "0x427bF5b37357632377eCbEC9de3626C71A5396c1",
            "factory": "0x0BFbCF9fa4f9C56B0F40a671Ad40E0805A091865",
            "weth": "0xae13d989daC2f0dEbFf460aC112a837C89BAa7cd",
            "quoteContract": "0xbC203d7f83677c7ed3F7acEc959963E7F4ECC5C2",
            "swapRouter": "0x1b81D678ffb9C0263b24A97847620C99d213eB14",
            // "swapRouter": "0xE4c6BBD69F923DFd1790c774c51014214262B867",
            "permit2": "0x31c2F6fcFf4F8759b3Bd5Bf0e1084A055615c768",
            "universalRouter": "0xd77C2afeBf3dC665af07588BF798bd938968c72E",
            "usdt": "0x337610d27c682E347C9cD60BD4b3b107C9d34dDd",
            "usdtFeePool": 500
        },

        // "fairLaunchFactory": "0xc4A3A05072D3875bdf936552e7426633D5280c48", 
        "fairLaunchFactory": "0xf0273F66Fe6Bd0c464Ab2d94e87B55FfEAE8Aa4e",
        "feePool": 2500,
        "icon": BNBLogo,
        "blockSeconds": 3
    }

}

export const CurrentChain = _CHAINS[process.env.REACT_APP_DEFAULT_CHAINID ? parseInt(process.env.REACT_APP_DEFAULT_CHAINID) : 1];
console.log("CurrentChain", process.env.REACT_APP_DEFAULT_CHAINID);


// const _chainId = parseInt(process.env.REACT_APP_DEFAULT_CHAINID ?? '1');

export const CHAINS: {
    [key: number]: Chain;
} = {
    56 : CurrentChain,
}

// export const CurrentChain = CHAINS[8453];
// export const CurrentChain = CHAINS[97];

export const supportedChains = Object.keys(CHAINS).map((key) => {
    return CHAINS[parseInt(key)];
});

